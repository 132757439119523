<template>
  <div id="page-valuation">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12">
          <v-card-title>{{ $vuetify.lang.t('$vuetify.different.stockValuation') }}</v-card-title>
          <v-card>
            <v-toolbar flat color="white">
              <v-text-field
                text
                solo
                flat
                clearable
                prepend-icon="search"
                :placeholder="$vuetify.lang.t('$vuetify.toolbar.search')"
                v-model="search"
                hide-details
                class="hidden-sm-and-down"
              ></v-text-field>
              <v-btn small rounded @click="getStock" :loading="valuationLoader" color="primary" class="mr-2">
                <v-icon>mdi-download</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.download') }}
              </v-btn>
              <v-btn small rounded @click="get" class="mr-2">
                <v-icon>mdi-refresh</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.refresh') }}
              </v-btn>
              <v-btn small rounded color="success" @click="dialog = !dialog">
                <v-icon>mdi-plus</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.create') }}
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="compHeaders"
                :items="items"
                fixed-header
                class="elevation-1"
                item-key="id"
                :loading="tableLoading"
                :options.sync="options"
                :server-items-length="totalItems"
              >
                <template v-slot:item.name="{ item }">
                  <a @click="view(item)">{{ item.name }}</a>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="download(item)"
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                        $vuetify.lang.t('$vuetify.buttons.download')
                      }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.sync="{ item }">
                  <v-simple-checkbox
                    v-model="item.sync"
                    disabled
                  ></v-simple-checkbox>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-show="!item.sync"
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="sync(item)"
                      >
                        <v-icon>mdi-sync</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                        $vuetify.lang.t('$vuetify.different.handle')
                      }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-on="on" v-bind="attrs" @click="view(item)">
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.ticket.view') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="deleteItem(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                        $vuetify.lang.t('$vuetify.buttons.delete')
                      }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog persistent v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="itemForm" lazy-validation v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    show-size
                    :rules="[rules.required]"
                    accept=".csv"
                    :label="$vuetify.lang.t('$vuetify.different.file') + '*'"
                    v-model="file"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*{{ $vuetify.lang.t('$vuetify.different.indicate') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
            :disabled="itemLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.close') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
            :disabled="!valid"
            :loading="itemLoading"
          >{{ compAction }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">{{
            $vuetify.lang.t('$vuetify.different.deleteItemConfirm')
          }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
            :disabled="itemLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteItemConfirm"
            :loading="itemLoading"
          >{{ $vuetify.lang.t('$vuetify.buttons.delete') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="itemsDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="itemsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
              $vuetify.lang.t('$vuetify.different.items')
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            light
            text
            solo
            flat
            clearable
            :placeholder="$vuetify.lang.t('$vuetify.toolbar.search')"
            v-model="itemsSearch"
            hide-details
            class="hidden-sm-and-down"
          ></v-text-field>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table
          :headers="compItemsHeaders"
          :items="fileItems"
          fixed-header
          class="elevation-1"
          item-key="id"
          :items-per-page="20"
          :loading="itemTableLoading"
          :options.sync="itemOptions"
          :server-items-length="totalFileItems"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100]
            }"
        >
          <template slot="body.append">
            <tr v-if="fileItems.length">
              <th></th>
              <th></th>
              <th>{{ totals.stock }}</th>
              <th>{{ totals.net }}</th>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.show = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <AppLoader :dialog.sync="loading"></AppLoader>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader'

export default {
  components: {
    AppLoader
  },
  data() {
    return {
      loading: false,
      valuationLoader: false,
      dialog: false,
      itemsDialog: false,
      dialogDelete: false,
      valid: true,
      itemLoading: false,
      tableLoading: false,
      itemTableLoading: false,
      search: null,
      itemsSearch: null,
      itemsAwaitingSearch: false,
      file: null,
      item: {
        id: null,
        file: null,
        created_at: null,
        updated_at: null
      },
      emptyItem: {
        id: null,
        file: null,
        created_at: null,
        updated_at: null
      },
      rules: {
        required: (v) =>
          !!v || this.$vuetify.lang.t('$vuetify.validation.required')
      },
      totalItems: 0,
      totalFileItems: 0,
      options: {},
      itemOptions: {},
      items: [],
      fileItems: [],
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
      errors: {
        file: null
      },
      defaultErrors: {
        file: null
      },
      totals: {
        stock: 0,
        net: 0
      },
      defaultTotals: {
        stock: 0,
        net: 0
      }
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.different.stockValuation')
    }
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.item = Object.assign({}, this.emptyItem)
        this.$refs.itemForm.resetValidation()
        this.file = null
        this.errors = Object.assign({}, this.defaultErrors)
      }
    },
    itemsDialog: function (val) {
      if (!val) {
        this.item = Object.assign({}, this.emptyItem)
        this.fileItems = []
      }
    },
    dialogDelete: function (val) {
      if (!val) {
        this.item = Object.assign({}, this.emptyItem)
      }
    },
    search(val) {
      if (!val || (val && val.length >= 1)) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.get()
            this.awaitingSearch = false
          }, 1000)
        }
        this.awaitingSearch = true
      }
    },
    itemsSearch(val) {
      if (!val || (val && val.length >= 1)) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getItems()
            this.awaitingSearch = false
          }, 1000)
        }
        this.awaitingSearch = true
      }
    },
    item: {
      handler() {
        this.validation()
      },
      deep: true
    },
    options: {
      handler() {
        this.get()
      },
      deep: true
    },
    itemOptions: {
      handler() {
        this.getItems()
      },
      deep: true
    }
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    },
    formTitle() {
      return this.item.id
        ? this.$vuetify.lang.t('$vuetify.buttons.edit')
        : this.$vuetify.lang.t('$vuetify.buttons.new')
    },
    compAction() {
      return this.item.id
        ? this.$vuetify.lang.t('$vuetify.buttons.save')
        : this.$vuetify.lang.t('$vuetify.buttons.create')
    },
    compHeaders() {
      return [
        {text: 'ID', value: 'id'},
        {
          text: this.$vuetify.lang.t('$vuetify.different.title'),
          value: 'name'
        },
        {text: this.$vuetify.lang.t('$vuetify.status.status'), value: 'sync'},
        {
          text: this.$vuetify.lang.t('$vuetify.dates.createdAt'),
          value: 'created_at'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false
        }
      ]
    },
    compItemsHeaders() {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.order.itemNumber'),
          value: 'item_number'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.itemName'),
          value: 'item_name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.inStock'),
          value: 'in_stock'
        },
        {text: this.$vuetify.lang.t('$vuetify.order.net'), value: 'net'}
      ]
    }
  },
  mounted() {
  },
  methods: {
    deleteItem(item) {
      this.item = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.itemLoading = true
      let headers = this.compAuthHeader
      headers.headers['Content-Type'] = 'application/json'
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/valuation/destroy',
          this.item,
          headers
        )
        .then((response) => {
          this.itemLoading = false
          if (response.status === 200) {
            this.get()
            this.dialogDelete = false

            this.snackbar.show = true
            this.snackbar.color = 'green'
            this.snackbar.text = this.$vuetify.lang.t(
              '$vuetify.messages.deleted'
            )
          } else {
            this.snackbar.show = true
            this.snackbar.color = 'red'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
          }
        })
        .catch((error) => {
          this.itemLoading = false
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
        })
    },
    sync(item) {
      this.loading = true
      let headers = this.compAuthHeader
      headers.headers['Content-Type'] = 'application/json'

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/valuation/sync',
          {id: item.id},
          headers
        )
        .then((response) => {
          this.loading = false
          if (response.status === 200) {
            this.get()

            this.snackbar.show = true
            this.snackbar.color = 'green'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.messages.sync')
          } else {
            this.snackbar.show = true
            this.snackbar.color = 'red'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
          }
        })
        .catch((error) => {
          this.loading = false
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
        })
    },
    save() {
      let t = this
      this.errors = Object.assign({}, this.defaultErrors)

      if (this.$refs.itemForm.validate()) {
        let formData = new FormData()
        if (this.file) formData.append('file', this.file)

        let headers = this.compAuthHeader
        headers.headers['Content-Type'] = 'multipart/form-data'

        this.itemLoading = true

        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/valuation/store',
            formData,
            headers
          )
          .then((response) => {
            this.itemLoading = false
            if (response.status === 200) {
              this.get()
              this.dialog = false
              this.file = null

              this.snackbar.show = true
              this.snackbar.color = 'green'
              this.snackbar.text = this.$vuetify.lang.t(
                '$vuetify.messages.saved'
              )
            } else if (
              response.status === 422 &&
              typeof response.data.errors !== 'undefined'
            ) {
              for (let key in response.data.errors) {
                response.data.errors[key].forEach(function (item) {
                  t.errors[key] = item
                })
              }
            } else {
              this.snackbar.show = true
              this.snackbar.color = 'red'
              this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
            }
          })
          .catch((error) => {
            this.itemLoading = false
            this.snackbar.show = true
            this.snackbar.color = 'red'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
          })
      }
    },
    get() {
      this.tableLoading = true
      let data = {
        search: this.search,
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort_by: this.options.sortBy[0],
        descending: this.options.sortDesc[0]
      }

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/valuation/index',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.items = response.data.data
            this.totalItems = response.data.meta.total
          }
          this.tableLoading = false
        })
        .catch((error) => {
          this.tableLoading = false
        })
    },
    getStock() {
      this.loading = this.valuationLoader = true
      let data = {
        project_id: 2,
      }
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
        responseType: 'blob'
      }

      this.$axios.post(this.$store.getters.getApiUrl + 'api/valuation/report', data, config)
        .then((response) => {
          if (response.status === 200) {
            const contentType = response.headers["content-type"];
            const downloadUrl = URL.createObjectURL(new Blob([response.data], {contentType}))
            const link = document.createElement('a')
            link.href = downloadUrl
            link.setAttribute('download', new Date().toISOString().slice(0, 10) + '_stock_valuation.xlsx')
            link.click()
          }
          this.loading = this.valuationLoader = false
        })
        .catch((error) => {
          this.loading = this.valuationLoader = false
        })
    },
    getItems() {
      this.itemTableLoading = true
      let data = {
        search: this.itemsSearch,
        page: this.itemOptions.page,
        per_page: this.itemOptions.itemsPerPage,
        id: this.item.id
      }
      if (this.itemOptions.sortBy) data.sort_by = this.itemOptions.sortBy[0]
      if (this.itemOptions.sortDesc)
        data.descending = this.itemOptions.sortDesc[0]

      let headers = this.compAuthHeader
      headers.headers['Content-Type'] = 'application/json'

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/valuation/items',
          data,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            this.fileItems = response.data.data
            this.totalFileItems = response.data.meta.total
            this.getTotals()
          } else this.itemTableLoading = false
        })
        .catch((error) => {
          this.itemTableLoading = false
        })
    },
    getTotals() {
      this.itemTableLoading = true
      let data = {
        search: this.itemsSearch,
        id: this.item.id
      }

      let headers = this.compAuthHeader
      headers.headers['Content-Type'] = 'application/json'

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/valuation/totals',
          data,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            this.totals = response.data.totals
          } else this.totals = Object.assign({}, this.defaultTotals)
          this.itemTableLoading = false
        })
        .catch((error) => {
          this.itemTableLoading = false
        })
    },
    validation() {
      for (let keyItem in this.item) {
        if (this.errors[keyItem]) this.errors[keyItem] = null
      }
    },
    download(item) {
      let config = this.compAuthHeader
      config.responseType = 'blob'

      let data = {
        id: item.id
      }

      this.loading = true

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/valuation/download',
          data,
          config
        )
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          )
          const link = document.createElement('a')
          link.href = downloadUrl
          link.setAttribute('download', item.name)
          document.body.appendChild(link)
          link.click()
          link.remove()
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    view(item) {
      this.item = Object.assign({}, item)
      this.itemsDialog = true
      this.getItems()
    }
  }
}
</script>
